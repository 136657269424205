import { useEffect } from 'react';
import { set as setCookie } from '@fiverr-private/layout_lib/cookie';
import { COOKIES } from '../../../constants';
import { Segment } from '../../../types';
import { createMixpanelReporter } from './mixpanel';

interface UseMessageEventsParams {
    cookiesDismissName: keyof typeof COOKIES;
    segment?: Segment;
}

const mixpanel = createMixpanelReporter({ eventNamePrefix: 'Winter Release Message' });

export const useMessageEvents = ({ segment, cookiesDismissName }: UseMessageEventsParams) => {
    useEffect(() => {
        if (segment) {
            mixpanel.trackView({ segment });
        }
    }, [segment]);

    const onDismissEvents = () => {
        if (segment) {
            mixpanel.trackClickOnDismiss({ segment });
        }
        const cookie = COOKIES[cookiesDismissName];
        setCookie(cookie.name, true, cookie.expiration);
    };

    const onCTAClickEvents = () => {
        if (segment) {
            mixpanel.trackClickOnCTA({ segment });
        }
    };

    return {
        onDismissEvents,
        onCTAClickEvents,
    };
};
