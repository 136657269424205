import type { SystemIconType } from '@fiverr-private/visuals';
import type { IconSizeType } from './types';

interface IconColors {
    light: SystemIconType['color'];
    dark: SystemIconType['color'];
}

export const ICON_SIZES: { [key in IconSizeType]: SystemIconType['size'] } = {
    xs: 'sm',
    md: 'md',
} as const;

export const ICON_COLORS: IconColors = {
    light: 'white',
    dark: 'grey_1200',
} as const;
