import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Box } from '@fiverr-private/theme';
import { PlaySolidIcon } from '@fiverr-private/visuals';
import { Button } from '@fiverr-private/button';
import { getContext } from '@fiverr-private/fiverr_context';
import { HEIGHT } from '../Message/MessageView/MessageViewContainer';
import { DismissButton } from '../Message/MessageView/DismissButton';
import { BannerComponentSSR } from '../../../types';
import { useMessageEvents } from '../Message';
import assetGradient from '../../../assets/go_gradual_asset.png';

import { gradientEffect } from '../Message/shared.ve.css';

const broadcastUrlGuest =
    'https://youtube.com/live/wY-a6aVvsUA?feature=share/?utm_source=release_message&utm_campaign=202502_release&utm_content=live';
const broadcastUrlLoggedIn =
    'https://connect.fiverr.com/home/events/fiverr-amplify-nyc-broadcast-e3cjizkzyq/?utm_source=release_message&utm_campaign=202502_release&utm_content=live';
const ctaUrl = '/go?utm_source=release_message&utm_campaign=202502_release&utm_content=live';

export const GoLiveBroadcast: React.FC<BannerComponentSSR> = ({ segment, closeOnDismiss }) => {
    const { userId } = getContext();
    const { onDismissEvents, onCTAClickEvents } = useMessageEvents({
        segment,
        cookiesDismissName: 'go_live_broadcast_release_message_hidden',
    });

    const handleOnDismiss = () => {
        onDismissEvents();
        closeOnDismiss();
    };

    const handleOnCTAClick = () => {
        onCTAClickEvents();
    };

    const text = translate('release_message.go_live_broadcast.message.segment_all');
    const textCTA = translate('release_message.go_live_broadcast.cta.segment_all');
    const textVideoCTA = translate('release_message.go_live_broadcast.cta.video');

    const broadcastUrl = userId ? broadcastUrlLoggedIn : broadcastUrlGuest;

    return (
        <>
            <Container
                position="relative"
                backgroundColor="black"
                height={75}
                padding="3"
                paddingLeft={{ default: '4', sm: '8', md: '16' }}
                paddingRight={{ default: '12', sm: '16' }}
                display="flex"
                alignItems="center"
            >
                <Stack
                    position="relative"
                    alignItems="center"
                    gap={{ default: '3', md: '8' }}
                    width="100%"
                    justifyContent={{ default: 'spaceBetween', md: 'center' }}
                >
                    <Container className={gradientEffect} position="absolute" maxHeight={HEIGHT}>
                        <img
                            src={assetGradient}
                            alt=""
                            role="presentation"
                            style={{ maxHeight: HEIGHT, position: 'relative' }}
                        />
                    </Container>
                    <Text color="white" zIndex="1" size={{ default: 'b_md', sm: 'b_lg' }}>
                        {text}
                    </Text>
                    <Stack gap="4" minWidth="fit-content">
                        <Button href={broadcastUrl} intent="secondary" size="sm" variant="filled" colorScheme="white">
                            <Box as="span">{textVideoCTA}</Box>
                            <PlaySolidIcon size="xs" />
                        </Button>
                        <Box as="span" hidden={{ default: true, sm: false }}>
                            <Button
                                href={ctaUrl}
                                target="_blank"
                                intent="secondary"
                                size="sm"
                                variant="outline"
                                colorScheme="white"
                                onClick={handleOnCTAClick}
                            >
                                {textCTA}
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
                <Container position="absolute" right={8} top={8} bottom={8} display="flex" alignItems="center">
                    <DismissButton onClick={handleOnDismiss} />
                </Container>
            </Container>
        </>
    );
};
