import { ROLLOUTS } from '@fiverr-private/layout-rollouts';

/** @deprecated */
export const COPIES_PREFIX = 'layout.release_message';

interface Cookie {
    name: string;
    expiration: number;
    rollout?: string;
}

const EXPIRATION_14_DAYS = 1000 * 3600 * 24 * 14;

export const COOKIES = {
    go_countdown_release_message_hidden: {
        rollout: ROLLOUTS.LAYOUT_RELEASE_MESSAGE_GO_COUNTER,
        name: 'go_countdown_release_message_hidden',
        expiration: EXPIRATION_14_DAYS,
    },
    go_launch_release_message_hidden: {
        rollout: ROLLOUTS.LAYOUT_RELEASE_MESSAGE_GO_LAUNCH,
        name: 'go_launch_release_message_hidden',
        expiration: EXPIRATION_14_DAYS,
    },
    go_live_broadcast_release_message_hidden: {
        rollout: ROLLOUTS.LAYOUT_RELEASE_MESSAGE_GO_LIVE_BROADCAST,
        name: 'go_live_broadcast_release_message_hidden',
        expiration: EXPIRATION_14_DAYS,
    },
    winter_release_message_hidden: {
        rollout: ROLLOUTS.DOLPHINS_WINTER_RELEASE_MESSAGE,
        name: 'winter_release_message_hidden',
        expiration: EXPIRATION_14_DAYS,
    },
    winter_release_message_user_segment: {
        name: 'winter_release_message_user_segment',
        expiration: EXPIRATION_14_DAYS,
    },
    /** @deprecated */
    release_message_hidden: {
        name: 'release_message_hidden',
        expiration: EXPIRATION_14_DAYS,
    },
    /** @deprecated */
    release_message_user_segment: {
        name: 'release_message_user_segment',
        expiration: EXPIRATION_14_DAYS,
    },
} as const satisfies Record<string, Cookie>;

export const PRO_PLANS_LINK = '/cp/pro-plans';
export const WINTER_RELEASE_LINK = '/cp/winter-release-25';

export const EXCLUDED_PATHS = [
    PRO_PLANS_LINK,
    WINTER_RELEASE_LINK,
    '/cp/lohp-v12',
    '/cp/team-account',
    '/pro-solutions/plans',
    '/pro-solutions/compliance-services',
    '/cp/compliance-freelancers',
    '/pro-solutions/worker-classification',
    '/pro-solutions/legal-documents',
    '/pro-solutions/background-checks',
] as const satisfies readonly string[];

export const EXCLUDED_PATHS_REGEXP = [
    // /digital-products-and-software/:username/microsoft365
    new RegExp(/^\/digital-products-and-software\/[^/]+\/microsoft365\/?$/),
] as const satisfies readonly RegExp[];
