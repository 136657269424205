import React from 'react';
import { ReleaseType } from '../configuration';
import { Segment } from '../types';
import { BombaBanner } from './components/BombaBanner/BombaBanner';

export interface EntrySSRProps {
    segment: Segment;
    releaseMessageId: ReleaseType | null;
}

function EntrySSR(props: EntrySSRProps) {
    if (!props.segment) {
        return null;
    }

    return <BombaBanner {...props} />;
}

// The default export is necessary for layout.config.json
export default EntrySSR;
