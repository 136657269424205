import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import type { BusinessPotential, LoyaltyTier, Segment } from '../types';

const TIMEOUT = 3000; // 3s

export const getInitialWinterSegment = async ({
    isRnc,
    isBusiness,
    isSignedIn,
    isProUser,
    isSeller,
}: {
    isRnc: boolean;
    isBusiness: boolean;
    isProUser: boolean;
    isSignedIn: boolean;
    isSeller: boolean;
}): Promise<Segment | null> => {
    if (isSeller) {
        return 'seller';
    }

    if (isBusiness) {
        if (!isProUser) {
            return 'pro_lohp_visitor';
        }

        if (isRnc) {
            return 'pro_silver';
        }

        if (isProUser) {
            const loyalty = await getProLoyaltyProgramStatus().catch(() => null);

            switch (loyalty) {
                case 'SILVER':
                    return 'pro_silver';
                case 'GOLD':
                    return 'pro_gold';
                case 'PLATINUM':
                    return 'pro_platinum';
                default:
                    return 'pro_lohp_visitor';
            }
        }
    } else if (!isBusiness && !isSignedIn) {
        return 'mp_guest';
    } else if (!isBusiness && isSignedIn) {
        const potential = await getBusinessPotential();
        if (potential === 'HIGH' || potential === 'MEDIUM') {
            return 'mp_pro_potential';
        }
        if (isRnc) {
            return 'mp_guest';
        }
        return null;
    }

    return null;
};

const getProLoyaltyProgramStatus = async (): Promise<LoyaltyTier | null> => {
    try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);
        const res = await axios.get(pathfinder('loyalty_dashboard_page_loyalty_status'), { signal: controller.signal });
        clearTimeout(timeoutId);

        const tier = res.data?.tier as LoyaltyTier;
        return tier;
    } catch (error) {
        if (axios.isCancel(error)) {
            logger.warn(
                `ReleaseMessageComponent getProLoyaltyProgramStatus | cancelled request after ${TIMEOUT}ms timeout`
            );
        } else if (axios.isAxiosError(error)) {
            logger.error('ReleaseMessageComponent getProLoyaltyProgramStatus | axios error: ', {
                error: error.toJSON(),
            });
        } else {
            logger.error('ReleaseMessageComponent getProLoyaltyProgramStatus | unknown error: ', { error });
        }

        return null;
    }
};

const getBusinessPotential = async (): Promise<BusinessPotential> => {
    try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);
        const res = await axios.get(pathfinder('business_user_potential'), { signal: controller.signal });
        clearTimeout(timeoutId);
        const potential: BusinessPotential = res.data?.potential || null;
        return potential;
    } catch (error) {
        if (axios.isCancel(error)) {
            logger.warn(`ReleaseMessageComponent getBusinessPotential | cancelled request after ${TIMEOUT}ms timeout`);
        } else if (axios.isAxiosError(error)) {
            logger.error('ReleaseMessageComponent getBusinessPotential | axios error: ', { error: error.toJSON() });
        } else {
            logger.error('ReleaseMessageComponent getBusinessPotential | unknown error: ', { error });
        }

        return 'UNKNOWN';
    }
};
