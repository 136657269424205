import React from 'react';
import { theme } from '@fiverr-private/theme';
import { SYSTEM_ICON_SIZES } from '../../constants';
import type { SystemIconType } from '../../types';

const TimesIcon = ({ size = 'sm', color = 'currentColor' }: SystemIconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SYSTEM_ICON_SIZES[size]}
    height={SYSTEM_ICON_SIZES[size]}
    fill={theme.colors[color]}
    viewBox="0 0 16 16"
  >
    <path d="m13.42 3.641-1.062-1.06L8 6.939 3.64 2.581 2.58 3.64l4.359 4.36-4.359 4.358 1.06 1.06L8 9.061l4.358 4.358 1.06-1.06L9.06 8z" />
  </svg>
);
TimesIcon.displayName = 'TimesIcon';
export default TimesIcon;
