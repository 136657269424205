import React from 'react';
import { theme } from '@fiverr-private/theme';
import { SYSTEM_ICON_SIZES } from '../../constants';
import type { SystemIconType } from '../../types';

const PlaySolidIcon = ({ size = 'sm', color = 'currentColor' }: SystemIconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SYSTEM_ICON_SIZES[size]}
    height={SYSTEM_ICON_SIZES[size]}
    fill={theme.colors[color]}
    viewBox="0 0 11 14"
  >
    <path d="M10.852 6.723.852.056a.333.333 0 0 0-.519.277v13.334a.333.333 0 0 0 .519.277l10-6.667a.333.333 0 0 0 0-.554" />
  </svg>
);
PlaySolidIcon.displayName = 'PlaySolidIcon';
export default PlaySolidIcon;
