import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Box } from '@fiverr-private/theme';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { Button } from '@fiverr-private/button';
import { BannerComponentSSR } from '../../../types';
import { DismissButton } from '../Message/MessageView/DismissButton';
import { HEIGHT } from '../Message/MessageView/MessageViewContainer';
import { useMessageEvents } from '../Message/useMessageEvents';
import assetGradient from '../../../assets/go_gradual_asset.png';
import { gradientEffect } from '../Message/shared.ve.css';
import { Timer } from './Timer/Timer';

const targetTimestamp = new Date('2025-02-18T17:30:00').getTime();
const ctaUrl =
    'https://connect.fiverr.com/home/events/fiverr-amplify-nyc-broadcast-e3cjizkzyq/?utm_source=release_message&utm_campaign=202502_release';

export const GoCountdown: React.FC<BannerComponentSSR> = ({ closeOnDismiss, segment }) => {
    const { onDismissEvents, onCTAClickEvents } = useMessageEvents({
        segment,
        cookiesDismissName: 'go_countdown_release_message_hidden',
    });

    const handleOnDismiss = () => {
        onDismissEvents();
        closeOnDismiss();
    };

    const handleOnCTAClick = () => {
        onCTAClickEvents();
    };

    const textLong = translate(`release_message.go_countdown.message_long.all`);
    const textCTAShort = translate(`release_message.go_countdown.cta_short`);
    const textCTALong = translate(`release_message.go_countdown.cta_long`);

    return (
        <Container
            position="relative"
            backgroundColor="black"
            height={75}
            padding="3"
            paddingLeft={{ default: '5', sm: '8', md: '16' }}
            paddingRight={{ default: '10', sm: '16' }}
            display="flex"
            alignItems="center"
        >
            <Container className={gradientEffect} position="absolute" maxHeight={HEIGHT}>
                <img
                    src={assetGradient}
                    alt=""
                    role="presentation"
                    style={{ maxHeight: HEIGHT, position: 'relative' }}
                />
            </Container>
            <Stack
                position="relative"
                alignItems="center"
                gap={{ default: '3', md: '8' }}
                width="100%"
                justifyContent={{ default: 'spaceBetween', md: 'center' }}
            >
                <Timer targetTimestamp={targetTimestamp} />
                <Text
                    color="white"
                    hidden={{ default: true, md: false }}
                    size={{ default: 'b_md', sm: 'b_lg', md: 'b_xl' }}
                    fontFamily="primary"
                >
                    {textLong}
                </Text>
                <Container minWidth="fit-content">
                    <Button
                        href={ctaUrl}
                        target="_blank"
                        intent="secondary"
                        size="sm"
                        variant="outline"
                        colorScheme="white"
                        onClick={handleOnCTAClick}
                    >
                        <Box as="span" hidden={{ default: false, sm: true }}>
                            {textCTAShort}
                        </Box>
                        <Box
                            as="span"
                            hidden={{ default: true, sm: false }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap="1.5"
                        >
                            {textCTALong}
                            <ArrowRightIcon />
                        </Box>
                    </Button>
                </Container>
            </Stack>
            <Container position="absolute" right={8} top={8} bottom={8} display="flex" alignItems="center">
                <DismissButton onClick={handleOnDismiss} />
            </Container>
        </Container>
    );
};
