
import { hydrateCSRTemplate } from "@fiverr-private/layout_lib/CSRTemplate";

import ReleaseMessage from '/home/circleci/app/packages/layout-release-message-component/src/entries/EntrySSR';

const element = typeof document !== 'undefined' && document.querySelector('#ReleaseMessage');
if (element) {
    const props = window.initialData['ReleaseMessage'];

    hydrateCSRTemplate(ReleaseMessage, props, element, 'ReleaseMessage', 'EntrySSR');
}
export default ReleaseMessage;
