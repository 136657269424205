import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Box } from '@fiverr-private/theme';
import { translate } from '@fiverr-private/i18n-react';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { Button } from '@fiverr-private/button';
import { DismissButton } from '../Message/MessageView/DismissButton';
import { HEIGHT } from '../Message/MessageView/MessageViewContainer';
import { BannerComponentSSR } from '../../../types';
import { useMessageEvents } from '../Message';
import assetGradient from '../../../assets/go_gradual_asset.png';
import { MessageText } from '../Message/MessageView/MessageText';

import { gradientEffect } from '../Message/shared.ve.css';

const urlCTA = '/go?utm_source=release_message&utm_campaign=202502_release&utm_content=launch';

export const GoLaunch: React.FC<BannerComponentSSR> = ({ closeOnDismiss, segment }) => {
    const { onDismissEvents, onCTAClickEvents } = useMessageEvents({
        segment,
        cookiesDismissName: 'go_launch_release_message_hidden',
    });

    const handleOnDismiss = () => {
        onDismissEvents();
        closeOnDismiss();
    };

    const handleOnCTAClick = () => {
        onCTAClickEvents();
    };

    const textLong = translate('release_message.go_launch.message_long.segment_all');
    const textShort = translate('release_message.go_launch.message_short.segment_all');
    const textCTA = translate('release_message.go_launch.cta.segment_all');

    return (
        <Container
            position="relative"
            backgroundColor="black"
            height={75}
            padding="3"
            paddingLeft={{ default: '4', sm: '8', md: '16' }}
            paddingRight={{ default: '12', sm: '16' }}
            display="flex"
            alignItems="center"
        >
            <Stack
                position="relative"
                alignItems="center"
                gap={{ default: '3', md: '8' }}
                width="100%"
                justifyContent={{ default: 'spaceBetween', md: 'center' }}
            >
                <Container className={gradientEffect} position="absolute" maxHeight={HEIGHT}>
                    <img
                        src={assetGradient}
                        alt=""
                        role="presentation"
                        style={{ maxHeight: HEIGHT, position: 'relative' }}
                    />
                </Container>
                <MessageText textShort={textShort} textLong={textLong} />
                <Button
                    target="_blank"
                    intent="secondary"
                    size="sm"
                    variant="outline"
                    colorScheme="white"
                    href={urlCTA}
                    onClick={handleOnCTAClick}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {textCTA}
                    <Box as="span" hidden={{ default: true, sm: false }} marginTop="1">
                        <ArrowRightIcon />
                    </Box>
                </Button>
            </Stack>
            <Container position="absolute" right={8} top={8} bottom={8} display="flex" alignItems="center">
                <DismissButton onClick={handleOnDismiss} />
            </Container>
        </Container>
    );
};
