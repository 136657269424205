import React from 'react';
import { Container } from '@fiverr-private/layout_components';

export const HEIGHT = 75;

type MessageViewContainerProps = Readonly<{
    children?: React.ReactNode;
}>;

export function MessageViewContainer({ children }: MessageViewContainerProps) {
    return (
        <Container
            position="relative"
            height={HEIGHT}
            padding="3"
            paddingLeft={{ default: '4', sm: '8', md: '16' }}
            paddingRight={{ default: '12', sm: '16' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            style={{ background: '#340F00' }}
        >
            {children}
        </Container>
    );
}
