import React from 'react';
import { Text } from '@fiverr-private/typography';

export interface MessageTextProps {
    textShort: string;
    textLong: string;
}

export function MessageText({ textShort, textLong }: MessageTextProps) {
    const props = {
        color: 'white',
        size: { default: 'b_md', sm: 'b_lg', md: 'b_xl' },
    } as const;
    return (
        <>
            <Text hidden={{ default: false, md: true }} zIndex="1" {...props}>
                {textShort}
            </Text>
            <Text hidden={{ default: true, md: false }} zIndex="1" {...props}>
                {textLong}
            </Text>
        </>
    );
}
