import React, { useState } from 'react';
import { ReleaseType } from '../../../configuration';
import { EntrySSRProps } from '../../EntrySSR';
import { EntryCSRProps } from '../../EntryCSR';
import { WinterReleaseMessage } from '../WinterReleaseMessage/WinterReleaseMessage';
import { GoCountdown } from '../GoCountdown/GoCountdown';
import { GoLaunch } from '../GoLaunch/GoLaunch';
import { GoLiveBroadcast } from '../GoLiveBroadcast/GoLiveBroadcast';

type BombaBannerProps = EntrySSRProps | EntryCSRProps;

const componentMapper: Record<ReleaseType, unknown> = {
    dolphins_winter_release_message: WinterReleaseMessage,
    layout_release_message_go_counter: GoCountdown,
    layout_release_message_go_live_broadcast: GoLiveBroadcast,
    layout_release_message_go_launch: GoLaunch,
};

export const BombaBanner: React.FC<BombaBannerProps> = (props) => {
    const [dismissed, setDismissed] = useState(false);

    const closeOnDismiss = () => {
        setDismissed(true);
    };

    const Component = componentMapper[props.releaseMessageId ?? ''];

    if (!Component || dismissed) return null;

    return <Component closeOnDismiss={closeOnDismiss} {...props} />;
};
