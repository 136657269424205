import React, { useEffect, useState } from 'react';
import { Domingo } from '@fiverr-private/domingo';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '../../utils/translate';
import { PRO_PLANS_LINK, WINTER_RELEASE_LINK } from '../../../constants';
import { MessageProps, useMessageEvents } from '../Message';
import { BannerComponentCSR, Segment } from '../../../types';
import { getInitialWinterSegment } from '../../getInitialSegment';
import { MessageView } from '../Message/MessageView';

type Winter25Segment = Exclude<Segment, 'buyer' | 'guest' | 'rnc' | 'logged_in' | 'all'>;

const PRO_SOURCE_MAP: Record<Exclude<Winter25Segment, 'mp_guest' | 'seller'>, string> = {
    pro_lohp_visitor: 'pro_lohp',
    pro_silver: 'pro_1',
    pro_gold: 'pro_2',
    pro_platinum: 'pro_3',
    mp_pro_potential: 'mp_1',
};

const getLink = ({ segment }: { segment: Segment }) => {
    const { url } = getContext();
    const proUrl = Domingo(url).removePath().pro().url;
    const mpUrl = Domingo(url).removePath().removePro().url;

    switch (segment) {
        case 'mp_guest':
            return `${mpUrl}${WINTER_RELEASE_LINK}/?utm_source=release_message&utm_campaign=202502_release&utm_content=guest&utm_term=all`;
        case 'seller':
            return `${mpUrl}${WINTER_RELEASE_LINK}/?utm_source=release_message&utm_campaign=202502_release&utm_content=registered&utm_term=freelancers#freelancers`;
        default:
            const source = PRO_SOURCE_MAP[segment];
            return `${proUrl}${PRO_PLANS_LINK}?source=${source}`;
    }
};

const getCopiesText = (): Record<
    Winter25Segment,
    Pick<MessageProps, 'textCTALong' | 'textCTAShort' | 'textLong' | 'textShort'>
> => ({
    pro_lohp_visitor: {
        textLong: translate('winter_release.message_long.lohp_visitor'),
        textShort: translate('winter_release.message_short.lohp_visitor'),
        textCTALong: translate('winter_release.cta.pro_guest'),
        textCTAShort: translate('winter_release.cta_short.pro'),
    },
    pro_silver: {
        textLong: translate('winter_release.message_long.pro_silver'),
        textShort: translate('winter_release.message_short.pro_silver'),
        textCTALong: translate('winter_release.cta.pro'),
        textCTAShort: translate('winter_release.cta_short.pro'),
    },
    pro_gold: {
        textLong: translate('winter_release.message_long.pro_gold'),
        textShort: translate('winter_release.message_short.pro_gold'),
        textCTALong: translate('winter_release.cta.pro'),
        textCTAShort: translate('winter_release.cta_short.pro'),
    },
    pro_platinum: {
        textLong: translate('winter_release.message_long.pro_platinum'),
        textShort: translate('winter_release.message_short.pro_platinum'),
        textCTALong: translate('winter_release.cta.pro'),
        textCTAShort: translate('winter_release.cta_short.pro'),
    },
    mp_pro_potential: {
        textLong: translate('winter_release.message_long.mp_pro_potential'),
        textShort: translate('winter_release.message_short.mp_pro_potential'),
        textCTALong: translate('winter_release.cta.mp'),
        textCTAShort: translate('winter_release.cta_short.mp'),
    },
    mp_guest: {
        textLong: translate('winter_release.message_long.mp_guest'),
        textShort: translate('winter_release.message_short.mp_guest'),
        textCTALong: translate('winter_release.cta.mp_guest'),
        textCTAShort: translate('winter_release.cta_short.mp_guest'),
    },
    seller: {
        textLong: translate('winter_release.message_long.seller'),
        textShort: translate('winter_release.message_short.seller'),
        textCTALong: translate('winter_release.cta.seller'),
        textCTAShort: translate('winter_release.cta_short.seller'),
    },
});

export interface WinterReleaseMessageProps extends BannerComponentCSR {}
export const WinterReleaseMessage: React.FC<WinterReleaseMessageProps> = ({
    segment,
    isRnc,
    isSeller,
    closeOnDismiss,
}) => {
    const [fetchedSegment, setFetchedSegment] = useState<Segment | undefined>(segment);
    const { onDismissEvents, onCTAClickEvents } = useMessageEvents({
        segment: fetchedSegment,
        cookiesDismissName: 'winter_release_message_hidden',
    });

    const {
        userId,
        organizationId,
        experience: { isBusiness },
    } = getContext();
    const isSignedIn = !!userId;
    const isProUser = !!organizationId;

    useEffect(() => {
        let shouldUpdate = true;

        (async () => {
            const segment = await getInitialWinterSegment({ isRnc, isSignedIn, isBusiness, isProUser, isSeller });

            if (shouldUpdate && segment) {
                setFetchedSegment(segment);
            }
        })();

        return () => {
            shouldUpdate = false;
        };
    }, [isRnc, isSignedIn, isBusiness, isProUser, isSeller]);

    // In this case we must to check for potential value, if exists
    if (!fetchedSegment) {
        return null;
    }

    const props: Pick<MessageProps, 'href' | 'textCTALong' | 'textCTAShort' | 'textLong' | 'textShort'> = {
        href: getLink({ segment: fetchedSegment }),
        ...getCopiesText()[fetchedSegment],
    };

    const handleOnDismiss = () => {
        onDismissEvents();
        closeOnDismiss();
    };

    return <MessageView onCTAClick={onCTAClickEvents} onDismiss={handleOnDismiss} {...props} />;
};
