import React from 'react';
import { Box } from '@fiverr-private/theme';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { Button } from '@fiverr-private/button';

type CTAButtonProps = Readonly<{
    href: string;
    textShort: string;
    textLong: string;
    onClick: () => void;
}>;

export function CTAButton({ href, textShort, textLong, onClick }: CTAButtonProps) {
    return (
        <Button
            href={href}
            target="_blank"
            intent="secondary"
            size="sm"
            variant="outline"
            colorScheme="white"
            onClick={onClick}
        >
            <Box as="span" hidden={{ default: false, md: true }}>
                {textShort}
            </Box>
            <Box as="span" hidden={{ default: true, md: false }}>
                {textLong}
            </Box>
            <ArrowRightIcon />
        </Button>
    );
}
