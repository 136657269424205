import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { containerClassName, imageClassName } from './Bulb.ve.css';

const IMAGE_SHIFT = -200;

type BulbProps = Readonly<{
    height: number;
}>;

const IMAGE_SRC =
    'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/892c9156638f8e6a2fe6b95dea910a69-1738084710299/circles2.png';

export function Bulb({ height }: BulbProps) {
    return (
        <Container
            className={containerClassName}
            hidden={{ default: true, sm: false }}
            height={height}
            position="absolute"
            left={IMAGE_SHIFT}
        >
            <img
                className={imageClassName}
                src={IMAGE_SRC}
                style={{ maxHeight: height, position: 'relative' }}
                // It's recommended to always have "alt" attribute for images.
                // But for decorative ones, it has to be empty.
                // Details:
                // - https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/alt#decorative_images
                // - https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/presentation_role
                alt=""
                role="presentation"
            />
        </Container>
    );
}
