import React, { forwardRef } from 'react';
import { TimesIcon } from '@fiverr-private/visuals';
import { translate } from '@fiverr-private/i18n-react';
import IconButton from '../IconButton';
import type { CloseButtonProps } from './types';
import { ICON_COLORS, ICON_SIZES } from './constants';

const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ size = 'md', color = 'dark', shape = 'square', onClick, dataTestId, className }, ref) => {
    const isLight = color === 'light';
    const outlineColor = isLight ? 'white' : undefined;

    return (
      <IconButton
        ref={ref}
        aria-label={translate('button.closeButton.close')}
        variant="ghost"
        shape={shape}
        size={size}
        onClick={onClick}
        data-testid={dataTestId}
        className={className}
        outlineColor={outlineColor}
        background={isLight ? { hover: `rgba(255, 255, 255, 0.1)`, active: `rgba(255, 255, 255, 0.2)` } : undefined}
      >
        <TimesIcon size={ICON_SIZES[size]} color={ICON_COLORS[color]} />
      </IconButton>
    );
  }
);

CloseButton.displayName = 'CloseButton';

export default CloseButton;
