import React, { useState, useEffect, useCallback } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const Divider = () => <Container as="span">&#58;</Container>;

export const Timer = ({ targetTimestamp }: { targetTimestamp: number }) => {
    const calculateTimeLeft = useCallback((): Partial<TimeLeft> => {
        const now = new Date().getTime();
        const difference = targetTimestamp - now;

        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }

        return timeLeft;
    }, [targetTimestamp]);

    const [timeLeft, setTimeLeft] = useState<Partial<TimeLeft>>(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetTimestamp, calculateTimeLeft]);

    return (
        <Stack width="150px" color="white" fontSize={{ default: 'b_lg', sm: 'b_xl' }} fontWeight="normal" gap="1">
            <Typography as="span">{formatTime(timeLeft.days)}</Typography>
            <Divider />
            <Typography as="span">{formatTime(timeLeft.hours)}</Typography>
            <Divider />
            <Typography as="span">{formatTime(timeLeft.minutes)}</Typography>
            <Divider />
            <Typography as="span">{formatTime(timeLeft.seconds)}</Typography>{' '}
        </Stack>
    );
};

const formatTime = (time) => (time < 10 ? `0${time}` : time);
