import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { CloseButton } from '@fiverr-private/button';

type DismissButtonProps = Readonly<{
    onClick: () => void;
}>;

export function DismissButton({ onClick }: DismissButtonProps) {
    const props = { color: 'light', onClick } as const;
    return (
        // This workaround is necessary because Penta "CloseButton"
        // doesn't support "size" prop as a responsive style.
        // Once https://github.com/fiverr/penta_sphinx/issues/551 resolved, the workaround can be escaped.
        <>
            <Container hidden={{ default: false, md: true }}>
                <CloseButton {...props} size="xs" />
            </Container>
            <Container hidden={{ default: true, md: false }}>
                <CloseButton {...props} size="md" />
            </Container>
        </>
    );
}
