import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Bulb } from './Bulb';
import { MessageText, MessageTextProps } from './MessageText';
import { CTAButton } from './CTAButton';
import { DismissButton } from './DismissButton';
import { MessageViewContainer, HEIGHT } from './MessageViewContainer';

interface MessageViewProps extends Pick<MessageTextProps, 'textShort' | 'textLong'> {
    textCTAShort: string;
    textCTALong: string;
    href: string;
    onDismiss: () => void;
    onCTAClick: () => void;
}

export function MessageView({
    textShort,
    textLong,
    textCTAShort,
    textCTALong,
    href,
    onDismiss,
    onCTAClick,
}: MessageViewProps) {
    return (
        <MessageViewContainer>
            <Stack position="relative" alignItems="center" gap={{ default: '3', md: '8' }}>
                <Bulb height={HEIGHT} />
                <MessageText textShort={textShort} textLong={textLong} />
                <Container minWidth="fit-content">
                    <CTAButton href={href} textShort={textCTAShort} textLong={textCTALong} onClick={onCTAClick} />
                </Container>
            </Stack>
            <Container
                position="absolute"
                right={8}
                top={8}
                bottom={8}
                display="flex"
                alignItems={{ default: 'flexStart', md: 'center' }}
            >
                <DismissButton onClick={onDismiss} />
            </Container>
        </MessageViewContainer>
    );
}
