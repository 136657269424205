/**
 * The related layout components rollouts.
 */
export const ROLLOUTS = {
    ANACONDAS_AGENCIES_BANNERS: 'anacondas_agencies_banners',
ANTS_BI_EVENTS: 'ants_bi_events',
ANTS_LAYOUT_USER_REALTIME_COMPONENT: 'ants_layout_user_realtime_component',
GOATS_APP_BOTTOM_SHEET_MOBILE: 'goats_app_bottom_sheet_mobile',
BUSINESS_PROJECTS_SEPARATION_SWITCH: 'business_projects_separation_switch',
CODE_ACTIVATION_PRO: 'code_activation_pro',
DOLPHINS_ACQUISITION_FLOWS_SEAMLESS_REG: 'dolphins_acquisition_flows_seamless_reg',
DOLPHINS_ACQUISITION_MODALS_SUBSCRIPTION: 'dolphins_acquisition_modals_subscription',
DOLPHINS_AVATAR_MENU_PRO_FEATURES_EXPERIMENT: 'dolphins_avatar_menu_pro_features_experiment',
DOLPHINS_FIVERR_PRO_AVATAR_MENU_EXTENSION: 'dolphins_fiverr_pro_avatar_menu_extension',
DOLPHINS_FULL_MIGRATION_MODAL_REPLACEMENT: 'dolphins_full_migration_modal_replacement',
DOLPHINS_IDENTIFICATION_BUSINESS: 'dolphins_identification_business',
DOLPHINS_LANDING_EXPERIENCE: 'dolphins_landing_experience',
DOLPHINS_LOHP_PRO_NEW_HEADER: 'dolphins_lohp_pro_new_header',
DOLPHINS_SEAMLESS_PAGE_GIG: 'dolphins_seamless_page_gig',
DOLPHINS_SEAMLESS_PAGE_LISTING: 'dolphins_seamless_page_listing',
DOLPHINS_SEAMLESS_PAGE_LOHP: 'dolphins_seamless_page_lohp',
DOLPHINS_SEAMLESS_REG_ANY_PAGE_NO_USAGE_PLAN: 'dolphins_seamless_reg_any_page_no_usage_plan',
DOLPHINS_SEAMLESS_REG_GIG_PAGE: 'dolphins_seamless_reg_gig_page',
DOLPHINS_UPGRADE_TO_PRO_ACQUISITION_MODAL: 'dolphins_upgrade_to_pro_acquisition_modal',
DOLPHINS_WHY_FIVERR_PRO_BUTTON: 'dolphins_why_fiverr_pro_button',
FALCONS_TEAM_ACCOUNT: 'falcons_team_account',
FIVERR_SELECT: 'fiverr_select',
GTM_ENABLED: 'gtm_enabled',
GOATS_GUEST_BOTTOM_APP_BANNER: 'goats_guest_bottom_app_banner',
PROMOTED_GIGS_SEARCH_BAR: 'promoted_gigs_search_bar',
LAYOUT_RELEASE_MESSAGE: 'layout_release_message',
LAYOUT_RELEASE_MESSAGE_GO_COUNTER: 'layout_release_message_go_counter',
LAYOUT_RELEASE_MESSAGE_GO_LAUNCH: 'layout_release_message_go_launch',
LAYOUT_RELEASE_MESSAGE_GO_LIVE_BROADCAST: 'layout_release_message_go_live_broadcast',
LIONS_MIGRATE_UPDATE_ROUTES_TO_V_FIVE: 'lions_migrate_update_routes_to_v_five',
LIONS_NAVIGATION_ITEMS_FOR_GERMAN_AUDIENCE: 'lions_navigation_items_for_german_audience',
LIONS_BANNER_LOCAL_SITE_REDIRECT: 'lions_banner_local_site_redirect',
LYNXES_IDENTIFICATION_MARKETPLACE_FULL_MIGRATION: 'lynxes_identification_marketplace_full_migration',
LYNXES_NEW_ACTIVATION_CODE: 'lynxes_new_activation_code',
MEERKATS_LOHP_REVAMP_TEST_OUT: 'meerkats_lohp_revamp_test_out',
MEERKATS_LOHP_REVAMP_RELEASE: 'meerkats_lohp_revamp_release',
MUSTANGS_LOYALTY_INDICATION_SAFETY: 'mustangs_loyalty_indication_safety',
MUSTANGS_MARKETPLACE_DYNAMIC_MATCHING: 'mustangs_marketplace_dynamic_matching',
MUSTANGS_PRO_DYNAMIC_MATCHING: 'mustangs_pro_dynamic_matching',
MUSTANGS_PRO_LIHP_DYNAMIC_MATCHING: 'mustangs_pro_lihp_dynamic_matching',
MUSTANGS_SHOW_BRIEF_AND_MATCH: 'mustangs_show_brief_and_match',
PERIMETERX: 'perimeterx',
PROFESSION_BASED_CATALOG_RELEASE_MESSAGE: 'profession_based_catalog_release_message',
PROJECT_PARTNER_HEADER: 'project_partner_header',
QUALAS_ROLLOUT_ACHIEVEMENTS_PERSEUS: 'qualas_rollout_achievements_perseus',
REAL_USERS_PERFORMANCE_MONITORING: 'real_users_performance_monitoring',
REAL_USERS_PERFORMANCE_MONITORING_NEXT: 'real_users_performance_monitoring_next',
CAT_RV_DATA_FETCH_SEARCH_BAR: 'cat_rv_data_fetch_search_bar',
QUALAS_SELLER_ANALYTICS_LOYALTY: 'qualas_seller_analytics_loyalty',
ANACONDAS_SELLER_PLUS_ANALYTICS_PAGE: 'anacondas_seller_plus_analytics_page',
ANACONDAS_SELLER_PLUS_ANALYTICS_KEYWORD_RESEARCH: 'anacondas_seller_plus_analytics_keyword_research',
SELLER_QUICK_SEARCH_NAVIGATION: 'seller_quick_search_navigation',
SHOW_ORDERS_NAVIGATOR_TO_RESTRICTED_USERS: 'show_orders_navigator_to_restricted_users',
STARLINGS_FIVERR_GO_FLOATING_CHAT: 'starlings_fiverr_go_floating_chat',
DOLPHINS_WINTER_RELEASE_MESSAGE: 'dolphins_winter_release_message',
WOLVES_FIB_BILLING_AREA: 'wolves_fib_billing_area',
ZEBRAS_INVITE_POLICIES_READ_NEW_DB: 'zebras_invite_policies_read_new_db',
ZEBRAS_INVITE_REQUESTS_CREATE_NEW_DB: 'zebras_invite_requests_create_new_db',
ZEBRAS_NEW_PROJECT_PARTNER_BANNER_IN_SEARCH: 'zebras_new_project_partner_banner_in_search',
ZEBRAS_POLICIES_REQUESTS_PAGE: 'zebras_policies_requests_page',
ZEBRAS_PRO_ADMIN: 'zebras_pro_admin',
ZEBRAS_PRO_SUBSCRIPTION: 'zebras_pro_subscription',
ZIPERS_FREELANCE_NETWORK: 'zipers_freelance_network'
} as const;

export type Rollout = (typeof ROLLOUTS)[keyof typeof ROLLOUTS];
