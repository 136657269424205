import React from 'react';
import { theme } from '@fiverr-private/theme';
import { SYSTEM_ICON_SIZES } from '../../constants';
import type { SystemIconType } from '../../types';

const ArrowRightIcon = ({ size = 'sm', color = 'currentColor' }: SystemIconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SYSTEM_ICON_SIZES[size]}
    height={SYSTEM_ICON_SIZES[size]}
    fill={theme.colors[color]}
    viewBox="0 0 15 11"
  >
    <path d="M9.923.969a.7.7 0 0 0-1.031 0 .8.8 0 0 0 0 1.089l2.804 2.961H1.486c-.403 0-.73.345-.73.77s.327.77.73.77h10.358L8.892 9.678a.8.8 0 0 0 0 1.09c.285.3.746.3 1.031 0l4.123-4.355a.8.8 0 0 0 0-1.09l-.07-.072-.009-.01z" />
  </svg>
);
ArrowRightIcon.displayName = 'ArrowRightIcon';
export default ArrowRightIcon;
