import type { BaseButtonProps } from '../BaseButton/types';
import type { SizeType } from './types';

export const SHAPES = {
    circle: 'circle',
    square: 'square',
} as const;

export const ICON_BUTTON_SIZES: {
    [key in SizeType]: {
        height: BaseButtonProps['height'];
        width: BaseButtonProps['width'];
    };
} = {
    xs: {
        height: '24px',
        width: '24px',
    },
    sm: {
        height: '36px',
        width: '36px',
    },
    md: {
        height: '42px',
        width: '42px',
    },
    lg: {
        height: '48px',
        width: '48px',
    },
};
