import { mixpanel } from '@fiverr-private/obs';
import type { Segment } from '../../../types';

type TrackCommonProps = Readonly<{
    segment: Segment;
}>;

export const createMixpanelReporter = ({ eventNamePrefix }: { eventNamePrefix: string }) => {
    const api = {
        trackView: ({ segment }: TrackCommonProps) => {
            mixpanel.track(`${eventNamePrefix} - Show`, {
                'User Type': getUserType(segment),
            });
        },
        trackClickOnCTA: ({ segment }: TrackCommonProps) => {
            mixpanel.track(`${eventNamePrefix} - Click`, {
                'User Type': getUserType(segment),
            });
        },
        trackClickOnDismiss: ({ segment }: TrackCommonProps) => {
            mixpanel.track(`${eventNamePrefix} - Close`, {
                'User Type': getUserType(segment),
            });
        },
    };

    return api;
};

const getUserType = (segment: Segment) => {
    switch (segment) {
        case 'pro_lohp_visitor':
            return 'Pro LOHP visitor';
        case 'pro_silver':
            return 'Pro Silver buyer / RNC';
        case 'pro_gold':
            return 'Pro Gold buyer';
        case 'pro_platinum':
            return 'Pro Platinum buyer';
        case 'mp_pro_potential':
            return 'MP Pro potential user';
        case 'mp_guest':
            return 'Guest';
        case 'seller':
            return 'Seller';
        default:
            return 'Buyer';
    }
};
